import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import "./styles/main.css";
import TagManager from "react-gtm-module";
import CookieBot from "react-cookiebot";
import loadable from '@loadable/component';

const Home = loadable(() => import('./pages/Home/index'));
const ApiPage = loadable(() => import('./pages/API'));
const PageNotFound = loadable(() => import('./components/NotFound/404'));
const Community = loadable(() => import('./pages/Community'));
const Tos = loadable(() => import('./pages/Terms'))
const Privacy = loadable(() => import('./pages/Privacy'))
const Artist = loadable(() => import('./pages/Artists/Artist'))
const Pricing = loadable(() => import('./pages/Pricing'));
const ArtistsPage = loadable(() => import('./pages/Artists/Artists'));
const ContactPage = loadable(() => import('./pages/Contact/ContactPage'));
const About = loadable(() => import('./pages/About'));
const HowItWorks = loadable(() => import('./pages/HowItWorks'));
const UseCase = loadable(() =>  import('./pages/UseCase'));
const usecaseId = loadable(() => import('./pages/UseCase/usecase/usecase[id]'));
const HowitWorksId = loadable(() => import('./pages/HowItWorks/HowitWork/HowitWorks[id]'));

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};
TagManager.initialize(tagManagerArgs);

const domainGroupId = process.env.REACT_APP_COOKIEBOT_ID;

function App() {
  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.pathname + window.location.search,
      },
    });
  }, []);

  return (
    <>
      <Router>
        <CookieBot domainGroupId={domainGroupId} className="none " />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/about" component={About} exact />
          <Route path="/pricing" component={Pricing} exact />
          <Route path="/usecases" component={UseCase} exact />
          <Route path="/usecase/:tag" component={usecaseId} exact />
          <Route path="/artists" component={ArtistsPage} exact />
          <Route path="/artists/:id" component={Artist} exact />
          <Redirect from="/howitworks" to="/how-it-works" />
          <Route path="/how-it-works" component={HowItWorks} exact />
          <Route path="/how-it-works/:tag" component={HowitWorksId} exact />
          <Route path="/contact" component={ContactPage} exact />
          <Route path="/community" component={Community} exact />
          <Route path="/api" component={ApiPage} exact />
          <Route
            path="/blog"
            render={() => (window.location = "https://www.beatoven.ai/blog/")}
            exact
          />
          <Route path="/tos" component={Tos} exact />
          <Route path="/privacy" component={Privacy} exact />
          <Route path="*" component={PageNotFound} />
          {/* <Route path="/copyright-claim" component={CopyrightClaim} exact /> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
